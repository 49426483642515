import { isHostAllowed } from "./_utils/helper"
import { createContext, useReducer, useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./components/routes/Home"
import Quote from "./components/routes/Quote"
import Setup from "./components/routes/Setup"
import Signup from "./components/routes/Signup"
import Error from "./components/routes/Error"
import { ScrollToTop } from "./components/ui/ScrollToTop"
import { useDevice } from "./hooks/useDevice"
import dayjs from "dayjs"
import _ from "lodash"

export const MyContext = createContext()

export function App() {
      const [ AppData, setAppData ] = useReducer(
            (oldData, newItem) => _.merge({}, oldData, newItem),
            { version: "0.1.0", status: "", loading: false }
      )

      const [ UserData, setUserData ] = useReducer(
            (oldData, newItem) => _.merge({}, oldData, newItem),
            {}
      )

      // Use the modified useDevice hook // something new here maybe
      useDevice(setUserData)

      useEffect(() => {
            // console.log(`AppData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(AppData))
      }, [ AppData ])

      useEffect(() => {
            // console.log(`UserData ${dayjs().format("HH:mm:ss.SSS")}`, JSON.stringify(UserData))
      }, [ UserData ])

      if (isHostAllowed()) {

            return (

                  <MyContext.Provider value={{ AppData, setAppData, UserData, setUserData }}>

                        <Router>

                              <ScrollToTop />

                              <Routes>

                                    <Route path="/" element={<Home />} />
                                    <Route path="/quote/:driverType" element={<Quote />} />
                                    <Route path="/setup" element={<Setup />} />
                                    <Route path="/signup" element={<Signup />} />
                                    <Route path="/*" element={<Error />} />

                              </Routes>

                        </Router>

                  </MyContext.Provider>
            )
      } else {

            return (

                  <Error />

            )
      }
}
